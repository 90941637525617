const programData = {
  homepage: {
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "70+",
      studentplaced: "2000+",
    },
    features: {
      first: "",
      second: "",
      third: "",
      forth: "",
    },
    footerbottomtext: "",
  },
  ThousandX: {
    programName: "1000x Placement Program",
    desc: " India's biggest and most exclusive placement program. Pay ₹1000 and get a ₹10 Lakh IT Job Guarantee.100% Refund if we fail to!",
    linktoregister: "https://chat.whatsapp.com/FmCT9fesL5v9Y1VYBAXrdS",
    registertoapply: true,
    instructer: [
      {
        name: "Aditya Sharma      ",
        desig: "SWE at Akamai Tech",
        about:
          " Hi, I'm Aditya and I have experience of training more than 200 students for DSA and have my self aced many coding rounds of top IT companies. I have landaded multiple superdream offers through regular and systematic practice of DSA. I am here to guide you for the same.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1ici6-DvAE-I7Aqwwn7K77BRaeH8psmIR",
      },
      {
        name: "Rohit Doshi",
        desig: "SDE at Amazon | 2x ICPC Regionalist",

        about:
          " Hi, I'm Rohit Doshi, and I had recieved multiple dream and super dream offers because of my DSA and problem solving skills. Also I have a great passion for teaching and making other people good at problem solving skills. I am here to help you develop one of the most important skills in acing any interviews.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1_FiJk_IAz6ocACXWnVH9HFWzYncVVlZw",
      },
      {
        name: "Nikeshsingh Baghel",
        desig: "SDE-T at ZS Associates",

        about:
          " Hi, I'm Nikeshsingh Baghel, I have appeared and cracked the Interviews for various IT companies, and this experience has landed me a super dream Job. According to my experience, acing the interview requires an in-depth knowledge of Core Subjects, and to get that knowledge we need to enjoy learning.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=15elP-zNzlXvNBqwhLCC5-kTIIbyHZHNz",
      },
      {
        name: "Rajat Agrawal",
        desig: "SWE-2 at Takeoff Technologies",

        about:
          " Hi, I'm Rajat Agrawal, not only do I have the experience of training over 1000 students for Aptitude tests of various tech companies but have myself aced through various aptitude tests that I appeared for & ultimately landed a superdream job. I'm here to guide you all for your placements.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1gKqITyYY3_gaq_eiAefhbO1cCwORTKa4",
      },
      {
        name: "Bharat Thakur",
        desig: "Candidate Master on Codeforces",

        about:
          "Hi, I'm Bharat Thakur, a Candidate Master on Codeforces. Competitive programming improved my grip over DSA & Problem Solving and helped me land a dream job. I'm here to mentor you for the same.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1bgCdJIwEmEiluQG-wEuTFc5GAfQ0PFg1",
      },
    ],
    pricing: "₹1000/-",
    features: {
      firstimg:
        "https://drive.google.com/thumbnail?id=177AWll1kihjAZ1tt9pWnHXQhkLNsfVBA",
      firstheading: " Exclusive",
      first: "Exclusive program for top students from different colleges.",
      secondimg:
        "https://drive.google.com/thumbnail?id=1FhdoY9khrv1p2eIyuJ6Zihvhp-iwdNVw",
      secondheading: "360 Placement Program",
      second:
        "Tailored program to help you prepare in every aspect of off-campus and on-campus placements.",
      thirdimg:
        "https://drive.google.com/thumbnail?id=11vYLiscmCtj_tuh9S0qPS_ihm7QoPiUp",
      thirdheading: "1000x or Refund*",
      third: "Get guaranteed 10 LPA+ offer or 100% refund.",
      forthimg:
        "https://drive.google.com/thumbnail?id=1aw2BXLq2SmSngxE8OtgCv6ebeaubo-JU",
      forth: "",
      forthheading: "",
    },
    showtimeline: true,
    showpricing: true,
    showyouwillget: true,
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "70+",
      studentplaced: "2000+",
    },
    youwillget: [
      "360 placement program of your dreams.",
      "Structured DSA preparation. ",
      "Complete Core subjects bootcamp. ",
      "Aptitude preparation for placements. ",
      "Interview Ready.",
    ],
    Eligibility: [
      " Exclusive program for top students from different colleges",
      "Fight hard to earn your spot!!",
    ],
    applyNowURL: "/1000x/#timeline",
    timelineformlink: "https://chat.whatsapp.com/By0cdzBRfZD8pmk9rLsKR6",
    footerbottomtext: "*T&C: Guaranteed package will depend on college",
  },
  Super50: {
    programName: "Super40",
    desc: " Exclusive program with a cap of max 40 students per batch, designed to make you placement ready right from DSA, Core Subjects & Aptitude to grab your dream job.",
    linktoregister:
      "https://docs.google.com/forms/d/e/1FAIpQLSdpbgY9WrAiJXFc-FdXftpKatZJq2KK3KIuUlRrzuHukCGR4g/viewform",
    registertoapply: true,
    instructer: [
      {
        name: "Aditya Sharma      ",
        desig: "SWE at Akamai Tech",
        about:
          " Hi, I'm Aditya and I have experience of training more than 200 students for DSA and have my self aced many coding rounds of top IT companies. I have landaded multiple superdream offers through regular and systematic practice of DSA. I am here to guide you for the same.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1ici6-DvAE-I7Aqwwn7K77BRaeH8psmIR",
      },
      {
        name: "Rohit Doshi",
        desig: "SDE at Amazon | 2x ICPC Regionalist",

        about:
          " Hi, I'm Rohit Doshi, and I had recieved multiple dream and super dream offers because of my DSA and problem solving skills. Also I have a great passion for teaching and making other people good at problem solving skills. I am here to help you develop one of the most important skills in acing any interviews.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1_FiJk_IAz6ocACXWnVH9HFWzYncVVlZw",
      },
      {
        name: "Nikeshsingh Baghel",
        desig: "SDE-T at ZS Associates",

        about:
          " Hi, I'm Nikeshsingh Baghel, I have appeared and cracked the Interviews for various IT companies, and this experience has landed me a super dream Job. According to my experience, acing the interview requires an in-depth knowledge of Core Subjects, and to get that knowledge we need to enjoy learning.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=15elP-zNzlXvNBqwhLCC5-kTIIbyHZHNz",
      },
      {
        name: "Rajat Agrawal",
        desig: "SWE-2 at Takeoff Technologies",

        about:
          " Hi, I'm Rajat Agrawal, not only do I have the experience of training over 1000 students for Aptitude tests of various tech companies but have myself aced through various aptitude tests that I appeared for & ultimately landed a superdream job. I'm here to guide you all for your placements.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1gKqITyYY3_gaq_eiAefhbO1cCwORTKa4",
      },
      {
        name: "Bharat Thakur",
        desig: "Candidate Master on Codeforces",

        about:
          "Hi, I'm Bharat Thakur, a Candidate Master on Codeforces. Competitive programming improved my grip over DSA & Problem Solving and helped me land a dream job. I'm here to mentor you for the same.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1bgCdJIwEmEiluQG-wEuTFc5GAfQ0PFg1",
      },
    ],
    pricing: "Option A:  Rs 7000 upfront ",
    priceplanA: "5450",
    priceplanB: "7450",
    alternativePrice: " ₹ 5450 or 7450 according to your plan",
    features: {
      firstimg:
        "https://drive.google.com/thumbnail?id=177AWll1kihjAZ1tt9pWnHXQhkLNsfVBA",
      firstheading: " Referrals Provided ",
      first:
        "Referrals of 40 Dream Companies including Amazon, VMware, Cred, Akamai, etc (check entire list above) will be provided based on openings.",
      secondimg:
        "https://drive.google.com/thumbnail?id=1FhdoY9khrv1p2eIyuJ6Zihvhp-iwdNVw",
      secondheading: "360 Placement Program",
      second:
        "Tailored program to help you prepare in every aspect of off-campus and on-campus placements.",
      thirdimg: "https://i.postimg.cc/8PZgpCvz/44.png",
      thirdheading: "100% Placements",
      third:
        "All of our previous batches have a 100% placement track record, we get each and every student placed with a good job. As a proof, check out the detailed list of each of our students' placement record below.",
      forthimg:
        "https://drive.google.com/thumbnail?id=1aw2BXLq2SmSngxE8OtgCv6ebeaubo-JU",
      forth:
        "In our 100+ hours of training, we cover every topic important for IT Placements right from DSA, Coding, Operating System, Database Management System, Computer Networks, Resume Building, Technical Interviews, HR Interviews, Aptitude & much more.",
      forthheading: "100+ Hours of Training",
    },
    showtimeline: true,
    showpricing: true,
    showyouwillget: true,
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "70+",
      studentplaced: "2000+",
    },
    youwillget: [
      "Referral of MNCs provided based on openings.",
      "Placement Drives featuring over 15 Dream Companies",
      "Full Stack Training for improved placement prospects.",
      "Craft a top-tier project to impress interviewers and nail placements.",
      "Structured DSA preparation.",
      "Complete Core subjects bootcamp.",
      "Aptitude preparation for placements.",
      "Interview Ready.",
      "360 placement program of your dreams.",
      "100+ hours of classes",
    ],
    Eligibility: [
      " Exclusive program for top students from different colleges",
      "Fight hard to earn your spot!!",
    ],
    timeline: {
      first:
        "Pay the upfront fees of ₹ 5450 or ₹ 7450 according to your preferred plan on given QR code.",
      second:
        "Once payment is done, fill this form to complete your registration.",
    },
    timelineformlink: "https://forms.gle/TckUMuvqnXFvScM57",
    footerbottomtext: "",
    applyNowURL: "/super40/#timeline",
    shownote: true,
  },
  JobSwitchPro: {
    programName: "JobSwitchPro",
    desc: "Switch to your dream high paying software job with ₹0 upfront fees within 30 days. Direct referrals at 20+ Product Based Companies, Interviews at 15+ Startups, Mock/Practice Placement Drives.",
    linktoregister: "",
    registertoapply: true,
    instructer: [],
    pricing: "Option A:  Rs 7000 upfront ",
    priceplanA: "999",
    priceplanB: "19,999",
    alternativePrice: " ₹ 5450 or 7450 according to your plan",
    features: {
      firstimg: "",
      firstheading: "₹0 Upfront Fees",
      first:
        "We charge ₹0 upfront fees, you can simply enroll for the program with the nominal registration token (₹999) & pay the fees (20% of only your 1st month's salary) only after a successful job switch, don't pay if we fail to help you switch your job.",
      secondimg: "",
      secondheading: "Direct Referrals",
      second:
        "No more asking 100s of people for referrals on linkedin & getting 1. We provide direct referrals at Amazon, VMware, Uber & 20+ high paying product based companies, so that you can focus on preparation & leave the rest to us.",
      thirdImg: "",
      thirdheading: "Interviews at 15+ Startups",
      third:
        "We conduct placement drives/tests, reach out to startups with your test scores & provide you opportunities to interview (subjective to your test scores) at high paying startups.",
      forthimg: "",
      forthheading: "Mock/Practice Placement Drives",
      forth:
        "To bring your A-Game while you sit for these companies, we conduct multiple mock/practice placement drives every few days to provide you pin-pointed analysis of your weak areas, improve on them & catalyse your preparation to ultimately ace the interviews. ",
    },
    showtimeline: true,
    showpricing: true,
    showyouwillget: true,
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "70+",
      studentplaced: "2000+",
    },
    youwillget: [
      "No Fees until Successful Job Switch",
      "Direct Referrals at 20+ Product Based Companies",
      "Placement Drives of 15+ Startups",
      "Mock/Practice Placement Drives",
    ],
    Eligibility: [
      " Exclusive program for top students from different colleges",
      "Fight hard to earn your spot!!",
    ],
    timeline: {
      first:
        "Pay the upfront token ₹999 for plan A or the fees ₹19,999 for plan B on given QR code.",
      second:
        "Once payment is done, fill this form to complete your registration.",
    },
    timelineformlink: "",
    footerbottomtext: "",
    applyNowURL: "/job-switch-pro/#timeline",
    shownote: true,
  },
  InterviewGenie: {
    programName: "InterviewGenie",
    desc: "Series of 5 Live Mock Interviews curated by IT Professionals intricately designed to mimic the actual IT Interview Experience covering every topic important for IT Placements, assisted by our game changing tech platform providing detailed reports on pinpointed weak area analysis & improvement plan for every interview. Ready to get placed?",
    linktoregister: "",
    registertoapply: true,
    instructer: [
      {
        name: "Aditya Sharma      ",
        desig: "SWE at Akamai Tech",
        about:
          " Hi, I'm Aditya and I have experience of training more than 200 students for DSA and have my self aced many coding rounds of top IT companies. I have landaded multiple superdream offers through regular and systematic practice of DSA. I am here to guide you for the same.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1ici6-DvAE-I7Aqwwn7K77BRaeH8psmIR",
      },
      {
        name: "Rohit Doshi",
        desig: "SDE at Amazon | 2x ICPC Regionalist",

        about:
          " Hi, I'm Rohit Doshi, and I had recieved multiple dream and super dream offers because of my DSA and problem solving skills. Also I have a great passion for teaching and making other people good at problem solving skills. I am here to help you develop one of the most important skills in acing any interviews.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1_FiJk_IAz6ocACXWnVH9HFWzYncVVlZw",
      },
      {
        name: "Nikeshsingh Baghel",
        desig: "SDE-T at ZS Associates",

        about:
          " Hi, I'm Nikeshsingh Baghel, I have appeared and cracked the Interviews for various IT companies, and this experience has landed me a super dream Job. According to my experience, acing the interview requires an in-depth knowledge of Core Subjects, and to get that knowledge we need to enjoy learning.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=15elP-zNzlXvNBqwhLCC5-kTIIbyHZHNz",
      },
      {
        name: "Rajat Agrawal",
        desig: "SWE-2 at Takeoff Technologies",

        about:
          " Hi, I'm Rajat Agrawal, not only do I have the experience of training over 1000 students for Aptitude tests of various tech companies but have myself aced through various aptitude tests that I appeared for & ultimately landed a superdream job. I'm here to guide you all for your placements.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1gKqITyYY3_gaq_eiAefhbO1cCwORTKa4",
      },
      {
        name: "Bharat Thakur",
        desig: "Candidate Master on Codeforces",

        about:
          "Hi, I'm Bharat Thakur, a Candidate Master on Codeforces. Competitive programming improved my grip over DSA & Problem Solving and helped me land a dream job. I'm here to mentor you for the same.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1bgCdJIwEmEiluQG-wEuTFc5GAfQ0PFg1",
      },
    ],
    pricing: "",
    priceplanA: "1999",
    priceplanB: "2999",
    alternativePrice: "",
    features: {
      firstimg: "",
      firstheading: "Specially Designed Live Interviews",
      first:
        "All the 5 Interviews are Live & specially tailored to mimic the actual IT Placement Experience.",
      secondimg: "",
      secondheading: "All Topics Covered",
      second:
        "Our Interviews cover every topic important for IT Placements including DSA, Coding, Operating Systems, Computer Networks, Database Management System, Projects/Resume Related Questions & much more.",
      thirdImg: "",
      thirdheading: "Game Changing Tech Platform",
      third:
        "You get access to our game changing tech platform that provides detailed reports covering your interview readiness level, pinpointed weak area analysis & improvement plan for each and every interview. ",
      forthimg: "",
      forthheading: "Domain Specialists",
      forth:
        "Our Interviews are curated by IT Professionals who are already placed in superdream companies like Amazon, VMware, Takeoff Technologies etc.",
    },
    showtimeline: true,
    showpricing: true,
    showyouwillget: true,
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "70+",
      studentplaced: "2000+",
    },
    youwillget: [
      "All interviews live and designed to simulate real IT placement experiences.",
      "Cover crucial IT placement topics like DSA, coding, OS, networks, databases, and more",
      "Access our tech platform for detailed reports on your interview readiness, identifying weak areas and offering improvement plans for each interview.",
    ],
    Eligibility: [],
    timeline: {
      first:
        "Pay the upfront fees of ₹1999 for Plan A or ₹2999 for Plan B on given QR code.",
      second:
        "Once payment is done, fill this form to complete your registration.",
    },
    timelineformlink: "https://forms.gle/JgBHFuWMdaqqNvyD7",
    footerbottomtext: "",
    applyNowURL: "/interview-genie/#timeline",
    shownote: true,
  },
  placekit: {
    programName: "Placement Scholarship",
    desc: "The Complete Placement Preparation Kit is a Placement Training Program for the deserving ones providing you with not just complete Coding + DSA training but also the chance to sit for PLACEMENT DRIVES of 15+ DREAM COMPANIES along with several mock practice tests, a full stack internship, live project building & much more!",
    linktoregister:
      "https://docs.google.com/forms/d/e/1FAIpQLSdpbgY9WrAiJXFc-FdXftpKatZJq2KK3KIuUlRrzuHukCGR4g/viewform",
    registertoapply: true,
    instructer: [
      {
        name: "Aditya Sharma",
        desig: "SWE at Akamai Tech",
        about:
          " Hi, I'm Aditya and I have experience of training more than 200 students for DSA and have my self aced many coding rounds of top IT companies. I have landaded multiple superdream offers through regular and systematic practice of DSA. I am here to guide you for the same.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1ici6-DvAE-I7Aqwwn7K77BRaeH8psmIR",
      },
      {
        name: "Rohit Doshi",
        desig: "SDE at Amazon | 2x ICPC Regionalist",

        about:
          " Hi, I'm Rohit Doshi, and I had recieved multiple dream and super dream offers because of my DSA and problem solving skills. Also I have a great passion for teaching and making other people good at problem solving skills. I am here to help you develop one of the most important skills in acing any interviews.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1_FiJk_IAz6ocACXWnVH9HFWzYncVVlZw",
      },
      {
        name: "Nikeshsingh Baghel",
        desig: "SDE-T at ZS Associates",
        about:
          " Hi, I'm Nikeshsingh Baghel, I have appeared and cracked the Interviews for various IT companies, and this experience has landed me a super dream Job. According to my experience, acing the interview requires an in-depth knowledge of Core Subjects, and to get that knowledge we need to enjoy learning.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=15elP-zNzlXvNBqwhLCC5-kTIIbyHZHNz",
      },
      {
        name: "Rajat Agrawal",
        desig: "SWE-2 at Takeoff Technologies",
        about:
          " Hi, I'm Rajat Agrawal, not only do I have the experience of training over 1000 students for Aptitude tests of various tech companies but have myself aced through various aptitude tests that I appeared for & ultimately landed a superdream job. I'm here to guide you all for your placements.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1gKqITyYY3_gaq_eiAefhbO1cCwORTKa4",
      },
      {
        name: "Bharat Thakur",
        desig: "Candidate Master on Codeforces",
        about:
          "Hi, I'm Bharat Thakur, a Candidate Master on Codeforces. Competitive programming improved my grip over DSA & Problem Solving and helped me land a dream job. I'm here to mentor you for the same.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1bgCdJIwEmEiluQG-wEuTFc5GAfQ0PFg1",
      },
    ],
    pricing: "₹ 0",
    priceplanA: "1999",
    cutPricing: "₹ 9000",
    priceplanB: "9000",
    alternativePrice: " transfer ₹ 999 ",
    perDayPrice: "999/180 = ₹5.55/day",
    item: "Cold Drink 🥤",
    itemPrice: "20",
    perDayPriceOnly: "₹5.55",
    features: {
      firstimg:
        "https://drive.google.com/thumbnail?id=177AWll1kihjAZ1tt9pWnHXQhkLNsfVBA",
      firstheading: "Referrals Provided ",
      first:
        " Referrals of 20+ Top Dream Companies including Amazon, VMware, Akamai, ZS & many more will be provided to you based on openings.",
      secondimg:
        "https://drive.google.com/thumbnail?id=1FhdoY9khrv1p2eIyuJ6Zihvhp-iwdNVw",
      secondheading: "360 Placement Program",
      second:
        "Tailored program to help you prepare in every aspect of off-campus and on-campus placements.",
      thirdimg: "https://i.postimg.cc/8PZgpCvz/44.png",
      thirdheading: "Complete Coding + DSA Training",
      third:
        "  Get complete Coding + DSA training in just 20 days, covering every important topic for placements right from scratch, conducted by Superdream Placed Senior.",
      forthimg:
        "https://drive.google.com/thumbnail?id=1aw2BXLq2SmSngxE8OtgCv6ebeaubo-JU",
      forth: "", //default lines already written in component
      forthheading: "",
    },
    showtimeline: true,
    showpricing: true,
    showyouwillget: true,
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "70+",
      studentplaced: "2000+",
    },
    youwillget: [
      "100% Scholarship with no course fees (only registration token to be paid)",
      "Tailormade platform for DSA & Problem Solving",
      "Referrals of Top MNCs provided based on openings",
      "Complete Coding + DSA training covering every important topic for placements & much more",
      "Full Stack Training Internship + Live Project Building to enhance the resume and impress the interviewer.",
      "Placement drives of 20+ dream companies",
      "Tailormade platform for core subjects & aptitude",
    ],
    Eligibility: [
      " Exclusive program for top students from different colleges",
      "Fight hard to earn your spot!!",
    ],
    timeline: {
      first:
        "Fill the following google form to apply for Placement Scholarship shortlisting process",
    },
    timelineformlink: "https://forms.gle/UR3VGzpyam4Vz1Je8",
    footerbottomtext: "",
    applyNowURL: "/placekit/#timeline",
    shownote: false,
  },
  PlacementScholarship2024: {
    programName: "Placement Scholarship 2024",
    desc: "The Complete Placement Preparation Kit is a Placement Training Program for the deserving ones providing you with not just complete Coding + DSA training but also the chance to sit for PLACEMENT DRIVES of 15+ DREAM COMPANIES along with several mock practice tests, a full stack internship, live project building & much more!",
    linktoregister:
      "https://docs.google.com/forms/d/e/1FAIpQLSdpbgY9WrAiJXFc-FdXftpKatZJq2KK3KIuUlRrzuHukCGR4g/viewform",
    registertoapply: true,
    instructer: [
      {
        name: "Aditya Sharma",
        desig: "SWE at Akamai Tech",
        about:
          " Hi, I'm Aditya and I have experience of training more than 200 students for DSA and have my self aced many coding rounds of top IT companies. I have landaded multiple superdream offers through regular and systematic practice of DSA. I am here to guide you for the same.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1ici6-DvAE-I7Aqwwn7K77BRaeH8psmIR",
      },
      {
        name: "Rohit Doshi",
        desig: "SDE at Amazon | 2x ICPC Regionalist",

        about:
          " Hi, I'm Rohit Doshi, and I had recieved multiple dream and super dream offers because of my DSA and problem solving skills. Also I have a great passion for teaching and making other people good at problem solving skills. I am here to help you develop one of the most important skills in acing any interviews.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1_FiJk_IAz6ocACXWnVH9HFWzYncVVlZw",
      },
      {
        name: "Nikeshsingh Baghel",
        desig: "SDE-T at ZS Associates",
        about:
          " Hi, I'm Nikeshsingh Baghel, I have appeared and cracked the Interviews for various IT companies, and this experience has landed me a super dream Job. According to my experience, acing the interview requires an in-depth knowledge of Core Subjects, and to get that knowledge we need to enjoy learning.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=15elP-zNzlXvNBqwhLCC5-kTIIbyHZHNz",
      },
      {
        name: "Rajat Agrawal",
        desig: "SWE-2 at Takeoff Technologies",
        about:
          " Hi, I'm Rajat Agrawal, not only do I have the experience of training over 1000 students for Aptitude tests of various tech companies but have myself aced through various aptitude tests that I appeared for & ultimately landed a superdream job. I'm here to guide you all for your placements.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1gKqITyYY3_gaq_eiAefhbO1cCwORTKa4",
      },
      {
        name: "Bharat Thakur",
        desig: "Candidate Master on Codeforces",
        about:
          "Hi, I'm Bharat Thakur, a Candidate Master on Codeforces. Competitive programming improved my grip over DSA & Problem Solving and helped me land a dream job. I'm here to mentor you for the same.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1bgCdJIwEmEiluQG-wEuTFc5GAfQ0PFg1",
      },
    ],
    pricing: "₹ 0",
    priceplanA: "1999",
    cutPricing: "₹ 9000",
    priceplanB: "9000",
    alternativePrice: " transfer ₹ 999 ",
    perDayPrice: "999/180 = ₹5.55/day",
    item: "Cold Drink 🥤",
    itemPrice: "20",
    perDayPriceOnly: "₹5.55",
    features: {
      firstimg:
        "https://drive.google.com/thumbnail?id=177AWll1kihjAZ1tt9pWnHXQhkLNsfVBA",
      firstheading: "Referrals Provided ",
      first:
        " Referrals of 20+ Top Dream Companies including Amazon, VMware, Akamai, ZS & many more will be provided to you based on openings.",
      secondimg:
        "https://drive.google.com/thumbnail?id=1FhdoY9khrv1p2eIyuJ6Zihvhp-iwdNVw",
      secondheading: "360 Placement Program",
      second:
        "Tailored program to help you prepare in every aspect of off-campus and on-campus placements.",
      thirdimg: "https://i.postimg.cc/8PZgpCvz/44.png",
      thirdheading: "Complete Coding + DSA Training",
      third:
        "  Get complete Coding + DSA training in just 20 days, covering every important topic for placements right from scratch, conducted by Superdream Placed Senior.",
      forthimg:
        "https://drive.google.com/thumbnail?id=1aw2BXLq2SmSngxE8OtgCv6ebeaubo-JU",
      forth: "", //default lines already written in component
      forthheading: "",
    },
    showtimeline: true,
    showpricing: true,
    showyouwillget: true,
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "70+",
      studentplaced: "2000+",
    },
    youwillget: [
      "100% Scholarship with no course fees (only registration token to be paid)",
      "Tailormade platform for DSA & Problem Solving",
      "Referrals of Top MNCs provided based on openings",
      "Complete Coding + DSA training covering every important topic for placements & much more",
      "Full Stack Training Internship + Live Project Building to enhance the resume and impress the interviewer.",
      "Placement drives of 20+ dream companies",
      "Tailormade platform for core subjects & aptitude",
    ],
    Eligibility: [
      " Exclusive program for top students from different colleges",
      "Fight hard to earn your spot!!",
    ],
    timeline: {
      first:
        "Fill the following google form to apply for Placement Scholarship shortlisting process",
    },
    timelineformlink: "https://forms.gle/UR3VGzpyam4Vz1Je8",
    footerbottomtext: "",
    applyNowURL: "/placement-scholarship-2024/#timeline",
    shownote: false,
  },
  coresubjectsforplacements: {
    programName: "CS Fundamentals",
    desc: " The complete placement preparation program for core subjects/CS fundamentals important for campus as well as off-campus placements covering technical aptitude, interviews to making you placement ready.    ",
    linktoregister:
      "https://docs.google.com/forms/d/e/1FAIpQLSdpbgY9WrAiJXFc-FdXftpKatZJq2KK3KIuUlRrzuHukCGR4g/viewform",
    registertoapply: true,
    instructer: [
      {
        name: "Aditya Sharma",
        desig: "SWE at Akamai Tech",
        about:
          " Hi, I'm Aditya and I have experience of training more than 200 students for DSA and have my self aced many coding rounds of top IT companies. I have landaded multiple superdream offers through regular and systematic practice of DSA. I am here to guide you for the same.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1ici6-DvAE-I7Aqwwn7K77BRaeH8psmIR",
      },
      {
        name: "Rohit Doshi",
        desig: "SDE at Amazon | 2x ICPC Regionalist",

        about:
          " Hi, I'm Rohit Doshi, and I had recieved multiple dream and super dream offers because of my DSA and problem solving skills. Also I have a great passion for teaching and making other people good at problem solving skills. I am here to help you develop one of the most important skills in acing any interviews.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1_FiJk_IAz6ocACXWnVH9HFWzYncVVlZw",
      },
      {
        name: "Nikeshsingh Baghel",
        desig: "SDE-T at ZS Associates",
        about:
          " Hi, I'm Nikeshsingh Baghel, I have appeared and cracked the Interviews for various IT companies, and this experience has landed me a super dream Job. According to my experience, acing the interview requires an in-depth knowledge of Core Subjects, and to get that knowledge we need to enjoy learning.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=15elP-zNzlXvNBqwhLCC5-kTIIbyHZHNz",
      },
      {
        name: "Rajat Agrawal",
        desig: "SWE-2 at Takeoff Technologies",
        about:
          " Hi, I'm Rajat Agrawal, not only do I have the experience of training over 1000 students for Aptitude tests of various tech companies but have myself aced through various aptitude tests that I appeared for & ultimately landed a superdream job. I'm here to guide you all for your placements.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1gKqITyYY3_gaq_eiAefhbO1cCwORTKa4",
      },
      {
        name: "Bharat Thakur",
        desig: "Candidate Master on Codeforces",
        about:
          "Hi, I'm Bharat Thakur, a Candidate Master on Codeforces. Competitive programming improved my grip over DSA & Problem Solving and helped me land a dream job. I'm here to mentor you for the same.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1bgCdJIwEmEiluQG-wEuTFc5GAfQ0PFg1",
      },
    ],
    pricing: "₹ 2999  ",
    priceplanA: "5000",
    cutPricing: "₹ 5999",
    priceplanB: "7000",
    alternativePrice: " transfer ₹ 2999 ",
    perDayPrice: "2999/180 = 16.66/day",
    item: "Cold Drink 🥤",
    itemPrice: "20",
    perDayPriceOnly: "₹16.66",
    features: {
      firstimg:
        "https://drive.google.com/thumbnail?id=177AWll1kihjAZ1tt9pWnHXQhkLNsfVBA",
      firstheading: "Referrals Provided ",
      first:
        " Referrals of Top Product Based Dream Companies including Amazon, VMware, Akamai, Zs etc will be provided based on openings.",
      secondimg:
        "https://drive.google.com/thumbnail?id=1FhdoY9khrv1p2eIyuJ6Zihvhp-iwdNVw",
      secondheading: "360 Placement Program",
      second:
        "Tailored program to help you prepare in every aspect of off-campus and on-campus placements.",
      thirdimg:
        // "https://drive.google.com/thumbnail?id=11vYLiscmCtj_tuh9S0qPS_ihm7QoPiUp",
        // "https://drive.google.com/thumbnail?id=177AWll1kihjAZ1tt9pWnHXQhkLNsfVBA",
        "https://i.postimg.cc/8PZgpCvz/44.png",

      thirdheading: "Hybrid Guidance Sessions",
      third:
        "  60+ hours of hybrid guidance sessions on DSA, OS, CN, DBMS, OOP, Aptitude, Coding Rounds, Technical Interviews, HR Interviews & much more",
      forthimg:
        "https://drive.google.com/thumbnail?id=1aw2BXLq2SmSngxE8OtgCv6ebeaubo-JU",
      forth: "", //default lines already written in component
      forthheading: "",
    },
    showtimeline: true,
    showpricing: true,
    showyouwillget: true,
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "70+",
      studentplaced: "2000+",
    },
    youwillget: [
      "Tailormade platform for core subjects & aptitude",
      "Tailormade platform for DSA & Problem Solving",
      "Referrals of Top MNCs provided based on openings",
      "60+ hours of guidance sessions on DSA, OS, CN, DBMS, OOP, Aptitude, Coding Rounds, Technical Interviews, HR Interviews & much more",
    ],
    Eligibility: [
      " Exclusive program for top students from different colleges",
      "Fight hard to earn your spot!!",
    ],
    timeline: {
      first:
        "Fill the following google form to apply for Placement Scholarship shortlisting process",
    },
    timelineformlink: "https://forms.gle/LPvv3VtVnBnhwKPJ7",
    footerbottomtext: "",
    applyNowURL: "/csfundamentals/#timeline",
    shownote: false,
  },
  PlacePrep360: {
    programName: "SDE Bootcamp",
    desc: " Exclusive program with very few seats per college, designed to make you placement ready right from DSA, Core Subjects & Aptitude to grab your dream job.",
    linktoregister: "https://chat.whatsapp.com/FmCT9fesL5v9Y1VYBAXrdS",
    registertoapply: true,
    instructer: [
      {
        name: "Aditya Sharma      ",
        desig: "SWE at Akamai Tech",
        about:
          " Hi, I'm Aditya and I have experience of training more than 200 students for DSA and have my self aced many coding rounds of top IT companies. I have landaded multiple superdream offers through regular and systematic practice of DSA. I am here to guide you for the same.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1ici6-DvAE-I7Aqwwn7K77BRaeH8psmIR",
      },
      {
        name: "Rohit Doshi",
        desig: "SDE at Amazon | 2x ICPC Regionalist",

        about:
          " Hi, I'm Rohit Doshi, and I had recieved multiple dream and super dream offers because of my DSA and problem solving skills. Also I have a great passion for teaching and making other people good at problem solving skills. I am here to help you develop one of the most important skills in acing any interviews.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1_FiJk_IAz6ocACXWnVH9HFWzYncVVlZw",
      },
      {
        name: "Nikeshsingh Baghel",
        desig: "SDE-T at ZS Associates",

        about:
          " Hi, I'm Nikeshsingh Baghel, I have appeared and cracked the Interviews for various IT companies, and this experience has landed me a super dream Job. According to my experience, acing the interview requires an in-depth knowledge of Core Subjects, and to get that knowledge we need to enjoy learning.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=15elP-zNzlXvNBqwhLCC5-kTIIbyHZHNz",
      },
      {
        name: "Rajat Agrawal",
        desig: "SWE-2 at Takeoff Technologies",

        about:
          " Hi, I'm Rajat Agrawal, not only do I have the experience of training over 1000 students for Aptitude tests of various tech companies but have myself aced through various aptitude tests that I appeared for & ultimately landed a superdream job. I'm here to guide you all for your placements.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1gKqITyYY3_gaq_eiAefhbO1cCwORTKa4",
      },
      {
        name: "Bharat Thakur",
        desig: "Candidate Master on Codeforces",

        about:
          "Hi, I'm Bharat Thakur, a Candidate Master on Codeforces. Competitive programming improved my grip over DSA & Problem Solving and helped me land a dream job. I'm here to mentor you for the same.",
        instructerimg:
          "https://drive.google.com/thumbnail?id=1bgCdJIwEmEiluQG-wEuTFc5GAfQ0PFg1",
      },
    ],
    pricing: "₹ 5950",
    cutPricing: "₹ 7500",
    priceplanA: "2500",
    priceplanB: "3500",
    perDayPrice: "5950/180 = ₹33/day",
    item: "Cadbury Silk 🍫",
    itemPrice: "70",
    alternativePrice: " transfer ₹5950 ",
    perDayPriceOnly: "₹33",
    features: {
      firstimg: "https://i.postimg.cc/8PZgpCvz/44.png",
      thirdheading: " Exclusive",
      third: "Exclusive program for top students from different colleges.",
      secondimg:
        "https://drive.google.com/thumbnail?id=1FhdoY9khrv1p2eIyuJ6Zihvhp-iwdNVw",
      secondheading: "360 Placement Program",
      second:
        "Tailored program to help you prepare in every aspect of off-campus and on-campus placements.",
      thirdimg:
        // "https://drive.google.com/thumbnail?id=11vYLiscmCtj_tuh9S0qPS_ihm7QoPiUp",
        // "https://drive.google.com/thumbnail?id=177AWll1kihjAZ1tt9pWnHXQhkLNsfVBA",
        "https://drive.google.com/thumbnail?id=177AWll1kihjAZ1tt9pWnHXQhkLNsfVBA",

      firstheading: "Referrals Provided ",
      first:
        " Referrals of Top Product Based Dream Companies including Amazon, VMware, Akamai, Zs etc will be provided based on openings.",
      forthimg:
        "https://drive.google.com/thumbnail?id=1aw2BXLq2SmSngxE8OtgCv6ebeaubo-JU",
      forth: "",
      forthheading: "",
    },
    showtimeline: true,
    showpricing: true,
    showyouwillget: true,
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "70+",
      studentplaced: "2000+",
    },
    youwillget: [
      "360 placement program of your dreams.",
      "Structured DSA preparation. ",
      "Core subjects practice & analysis ",
      "Aptitude practice & analysis ",
      "Interview Ready.",
      "60+ hours of classes",
    ],
    Eligibility: [
      " Exclusive program for top students from different colleges",
      "Fight hard to earn your spot!!",
    ],
    timeline: {
      first:
        "Fill the following google form to apply for PlacePrep 360 shortlisting process",
    },
    timelineformlink: "https://forms.gle/paNLiXkxcUTyKRAv8",
    footerbottomtext: "",
    applyNowURL: "/sdebootcamp/#timeline",
    shownote: false,
  },
  dsa: {
    programName: "DSA",
    desc: "",
    linktoregister: "",
    instructer: [
      {
        name: "Rishabh Rathi",
        about:
          "React Developer Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima expedita commodi id dolor ut ipsum officia numquam eius, quidem doloribus",
      },
    ],
    pricing: "2000",
    features: {
      first: "",
      second: "",
      third: "",
      forth: "",
    },
    showtimeline: false,
    showpricing: false,
    showyouwillget: false,
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "1300+",
      studentplaced: "2000+",
    },
    youwillget: [
      "An impressive project on your resume",
      "A trending skill to enhance your resume",
      " Course Certification",
      " Lifetime Access",
      " Internship offer to top performers",
    ],
    timelineformlink: "https://docs.google.com/forms/u/0",
    footerbottomtext: "",
  },
  coderun: {
    programName: "Coderun",
    desc: "",
    linktoregister: "",
    instructer: [
      {
        name: "Rishabh Rathi",
        about:
          "React Developer Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima expedita commodi id dolor ut ipsum officia numquam eius, quidem doloribus",
      },
    ],

    pricing: "2000",
    features: {
      first: "",
      second: "",
      third: "",
      forth: "",
    },
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "70+",
      studentplaced: "2000+",
    },
    showtimeline: false,
    showpricing: false,
    showyouwillget: false,
    youwillget: [
      "An impressive project on your resume",
      "A trending skill to enhance your resume",
      " Course Certification",
      " Lifetime Access",
      " Internship offer to top performers",
    ],
    timelineformlink: "https://docs.google.com/forms/u/0",
    footerbottomtext: "",
  },
  coresubjects: {
    programName: "CORE SUBJECTS",
    desc: "",
    linktoregister: "",
    instructer: [
      {
        name: "Rishabh Rathi",
        about:
          "React Developer Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima expedita commodi id dolor ut ipsum officia numquam eius, quidem doloribus",
      },
    ],
    showtimeline: false,
    showpricing: false,
    showyouwillget: false,
    pricing: "2000",
    features: {
      first: "",
      second: "",
      third: "",
      forth: "",
    },
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "70+",
      studentplaced: "2000+",
    },

    youwillget: [
      "An impressive project on your resume",
      "A trending skill to enhance your resume",
      " Course Certification",
      " Lifetime Access",
      " Internship offer to top performers",
    ],
    timelineformlink: "https://docs.google.com/forms/u/0",
    footerbottomtext: "",
  },
  pythonbootcamp: {
    programName: "PythonBootcamp",
    desc: "",
    linktoregister: "",
    instructer: [
      {
        name: "Rishabh Rathi",
        about:
          "React Developer Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima expedita commodi id dolor ut ipsum officia numquam eius, quidem doloribus",
      },
    ],
    pricing: "2000",
    features: {
      first: "",
      second: "",
      third: "",
      forth: "",
    },
    showtimeline: false,
    showpricing: false,
    showyouwillget: false,
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "70+",
      studentplaced: "2000+",
    },
    youwillget: [
      "An impressive project on your resume",
      "A trending skill to enhance your resume",
      " Course Certification",
      " Lifetime Access",
      " Internship offer to top performers",
    ],
    timelineformlink: "https://docs.google.com/forms/u/0",
    footerbottomtext: "",
  },
  sdebootcamp: {
    programName: "sdebootcamp",
    desc: "",
    linktoregister: "",
    instructer: [
      {
        name: "Rishabh Rathi",
        about:
          "React Developer Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima expedita commodi id dolor ut ipsum officia numquam eius, quidem doloribus",
      },
    ],

    pricing: "2000",
    features: {
      first: "",
      second: "",
      third: "",
      forth: "",
    },
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "70+",
      studentplaced: "2000+",
    },
    showtimeline: false,
    showpricing: false,
    showyouwillget: false,
    youwillget: [
      "An impressive project on your resume",
      "A trending skill to enhance your resume",
      " Course Certification",
      " Lifetime Access",
      " Internship offer to top performers",
    ],
    timelineformlink: "https://docs.google.com/forms/u/0",
    footerbottomtext: "",
  },
  aptitudeseries: {
    programName: "APTITUDE SERIES",
    desc: "",
    linktoregister: "",
    instructer: [
      {
        name: "Rishabh Rathi",
        about:
          "React Developer Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima expedita commodi id dolor ut ipsum officia numquam eius, quidem doloribus",
      },
    ],
    pricing: "2000",
    features: {
      first: "",
      second: "",
      third: "",
      forth: "",
    },
    showtimeline: true,
    showpricing: true,
    showyouwillget: true,
    stats: {
      placements: "100%",
      companycracked: "1300+",
      placedinamazon: "70+",
      studentplaced: "2000+",
    },
    youwillget: [
      "An impressive project on your resume",
      "A trending skill to enhance your resume",
      " Course Certification",
      " Lifetime Access",
      " Internship offer to top performers",
    ],
    timelineformlink: "https://docs.google.com/forms/u/0",
    footerbottomtext: "",
  },
};

export default programData;
